import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function BoletoDuplicatas() {
  const navigate = useNavigate();
  const [boletos, setBoletos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Campos do formulário
  const [formData, setFormData] = useState({
    documento: '',
    emissao: null,
    vencimento: null,
    cliente: '',
    cpfCnpj: '',
    valor: '',
    saldo: '',
    empresa: '',
    juros: '',
    multa: '',
    protesto: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSave = () => {
    const novoBoleto = { ...formData, id: editingId || Date.now() };

    if (editingId) {
      setBoletos((prev) =>
        prev.map((boleto) => (boleto.id === editingId ? novoBoleto : boleto))
      );
      setSnackbarMessage('Boleto atualizado com sucesso!');
    } else {
      setBoletos((prev) => [...prev, novoBoleto]);
      setSnackbarMessage('Boleto cadastrado com sucesso!');
    }

    setModalOpen(false);
    setSnackbarOpen(true);
    setEditingId(null);
    resetForm();
  };

  const handleEdit = (boleto) => {
    setFormData(boleto);
    setEditingId(boleto.id);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setBoletos((prev) => prev.filter((boleto) => boleto.id !== id));
    setSnackbarMessage('Boleto removido com sucesso!');
    setSnackbarOpen(true);
  };

  const resetForm = () => {
    setFormData({
      documento: '',
      emissao: null,
      vencimento: null,
      cliente: '',
      cpfCnpj: '',
      valor: '',
      saldo: '',
      empresa: '',
      juros: '',
      multa: '',
      protesto: '',
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Breadcrumb */}
      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        Boletos e Duplicatas
      </Typography>

      {/* Botão Adicionar */}
      <Box sx={{ marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Novo Boleto/Duplicata
        </Button>
      </Box>

      {/* Tabela */}
      <TableContainer component={Paper}>
      
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Documento</TableCell>
              <TableCell>Emissão</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Código/Nome do Cliente</TableCell>
              <TableCell>CPF/CNPJ</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Saldo</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Juros</TableCell>
              <TableCell>Multa</TableCell>
              <TableCell>Protesto</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boletos.map((boleto) => (
              <TableRow key={boleto.id}>
                <TableCell>{boleto.documento}</TableCell>
                <TableCell>{boleto.emissao?.toLocaleDateString()}</TableCell>
                <TableCell>{boleto.vencimento?.toLocaleDateString()}</TableCell>
                <TableCell>{boleto.cliente}</TableCell>
                <TableCell>{boleto.cpfCnpj}</TableCell>
                <TableCell>R$ {parseFloat(boleto.valor).toFixed(2)}</TableCell>
                <TableCell>R$ {parseFloat(boleto.saldo).toFixed(2)}</TableCell>
                <TableCell>{boleto.empresa}</TableCell>
                <TableCell>{boleto.juros}%</TableCell>
                <TableCell>{boleto.multa}%</TableCell>
                <TableCell>{boleto.protesto}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(boleto)} startIcon={<EditIcon />}>
                    Editar
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(boleto.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflow: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" mb={2}>
            {editingId ? 'Editar Boleto/Duplicata' : 'Novo Boleto/Duplicata'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Documento"
                name="documento"
                value={formData.documento}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Emissão"
                  value={formData.emissao}
                  onChange={(value) => handleDateChange('emissao', value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Vencimento"
                  value={formData.vencimento}
                  onChange={(value) => handleDateChange('vencimento', value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Código/Nome do Cliente"
                name="cliente"
                value={formData.cliente}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CPF/CNPJ"
                name="cpfCnpj"
                value={formData.cpfCnpj}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Valor"
                name="valor"
                type="number"
                value={formData.valor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Saldo"
                name="saldo"
                type="number"
                value={formData.saldo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Empresa"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Juros (%)"
                name="juros"
                type="number"
                value={formData.juros}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Multa (%)"
                name="multa"
                type="number"
                value={formData.multa}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Protesto"
                name="protesto"
                value={formData.protesto}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
