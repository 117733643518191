import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  MenuItem,
  Stack,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Caixa() {
  const [resumo, setResumo] = useState({ receitas: 0, despesas: 0, saldo: 0 });
  const [graficoBarras, setGraficoBarras] = useState([]);
  const [graficoPizza, setGraficoPizza] = useState([]);
  const [transacoes, setTransacoes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Campos do formulário
  const [formData, setFormData] = useState({
    tipo: 'Receita',
    categoria: '',
    descricao: '',
    valor: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const atualizarResumo = () => {
    const receitas = transacoes
      .filter((t) => t.tipo === 'Receita')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const despesas = transacoes
      .filter((t) => t.tipo === 'Despesa')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const saldo = receitas - despesas;

    setResumo({ receitas, despesas, saldo });

    // Gráfico de barras: Receitas e despesas por categoria
    const dadosPorCategoria = transacoes.reduce((acc, item) => {
      acc[item.categoria] = acc[item.categoria] || { receitas: 0, despesas: 0 };
      acc[item.categoria][item.tipo === 'Receita' ? 'receitas' : 'despesas'] += parseFloat(item.valor);
      return acc;
    }, {});

    setGraficoBarras(
      Object.keys(dadosPorCategoria).map((categoria) => ({
        categoria,
        receitas: dadosPorCategoria[categoria].receitas,
        despesas: dadosPorCategoria[categoria].despesas,
      }))
    );

    // Gráfico de pizza
    setGraficoPizza([
      { name: 'Receitas', value: receitas },
      { name: 'Despesas', value: despesas },
    ]);
  };

  const handleSave = () => {
    const novaTransacao = { ...formData, id: editingId || Date.now() };

    if (editingId) {
      setTransacoes((prev) => prev.map((t) => (t.id === editingId ? novaTransacao : t)));
      setSnackbarMessage('Transação atualizada com sucesso!');
    } else {
      setTransacoes((prev) => [...prev, novaTransacao]);
      setSnackbarMessage('Transação adicionada com sucesso!');
    }

    setModalOpen(false);
    setSnackbarOpen(true);
    setEditingId(null);
    resetForm();
    atualizarResumo();
  };

  const handleEdit = (transacao) => {
    setFormData(transacao);
    setEditingId(transacao.id);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setTransacoes((prev) => prev.filter((t) => t.id !== id));
    setSnackbarMessage('Transação removida com sucesso!');
    setSnackbarOpen(true);
    atualizarResumo();
  };

  const resetForm = () => {
    setFormData({
      tipo: 'Receita',
      categoria: '',
      descricao: '',
      valor: '',
    });
  };

  useEffect(() => {
    atualizarResumo();
  }, [transacoes]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Resumo Financeiro */}
      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Receitas</Typography>
            <Typography variant="h4" color="green">
              R$ {resumo.receitas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Despesas</Typography>
            <Typography variant="h4" color="red">
              R$ {resumo.despesas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Saldo</Typography>
            <Typography variant="h4" color={resumo.saldo >= 0 ? 'green' : 'red'}>
              R$ {resumo.saldo.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Gráfico de Barras */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Receitas e Despesas por Categoria
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={graficoBarras}>
            <XAxis dataKey="categoria" />
            <YAxis />
            <RechartTooltip />
            <Bar dataKey="receitas" fill="#4caf50" />
            <Bar dataKey="despesas" fill="#f44336" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      {/* Gráfico de Pizza */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Distribuição de Receitas e Despesas
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={graficoPizza}
              dataKey="value"
              nameKey="name"
              outerRadius={120}
              fill="#8884d8"
              label={(entry) => `${entry.name}: ${entry.value.toFixed(2)}`}
            >
              {graficoPizza.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.name === 'Receitas' ? '#4caf50' : '#f44336'}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Paper>

      {/* Botão Adicionar */}
      <Box sx={{ marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Nova Transação
        </Button>
      </Box>

      {/* Tabela */}
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography variant="h6">Histórico de Transações</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transacoes.map((transacao) => (
              <TableRow key={transacao.id}>
                <TableCell>{transacao.tipo}</TableCell>
                <TableCell>{transacao.categoria}</TableCell>
                <TableCell>{transacao.descricao}</TableCell>
                <TableCell>R$ {parseFloat(transacao.valor).toFixed(2)}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(transacao)} startIcon={<EditIcon />}>
                    Editar
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(transacao.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflow: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" mb={2}>
            {editingId ? 'Editar Transação' : 'Nova Transação'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Tipo"
                name="tipo"
                value={formData.tipo}
                onChange={handleChange}
              >
                <MenuItem value="Receita">Receita</MenuItem>
                <MenuItem value="Despesa">Despesa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Categoria"
                name="categoria"
                value={formData.categoria}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="descricao"
                value={formData.descricao}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Valor"
                name="valor"
                type="number"
                value={formData.valor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
