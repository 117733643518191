import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  MenuItem,
  Stack,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FolhaDePagamento() {
  const [resumo, setResumo] = useState({ pagamentos: 0, beneficios: 0, descontos: 0, custoTotal: 0 });
  const [graficoBarras, setGraficoBarras] = useState([]);
  const [graficoPizza, setGraficoPizza] = useState([]);
  const [folha, setFolha] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Campos do formulário
  const [formData, setFormData] = useState({
    nome: '',
    cargo: '',
    departamento: '',
    salario: '',
    beneficios: '',
    descontos: '',
    dataPagamento: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (value) => {
    setFormData({ ...formData, dataPagamento: value });
  };

  const atualizarResumo = () => {
    const pagamentos = folha.reduce((acc, item) => acc + parseFloat(item.salario), 0);
    const beneficios = folha.reduce((acc, item) => acc + parseFloat(item.beneficios), 0);
    const descontos = folha.reduce((acc, item) => acc + parseFloat(item.descontos), 0);
    const custoTotal = pagamentos + beneficios - descontos;

    setResumo({ pagamentos, beneficios, descontos, custoTotal });

    // Dados do gráfico de barras
    const pagamentosPorDepartamento = folha.reduce((acc, item) => {
      acc[item.departamento] = (acc[item.departamento] || 0) + parseFloat(item.salario);
      return acc;
    }, {});

    setGraficoBarras(
      Object.keys(pagamentosPorDepartamento).map((departamento) => ({
        name: departamento,
        pagamento: pagamentosPorDepartamento[departamento],
      }))
    );

    // Dados do gráfico de pizza
    setGraficoPizza([
      { name: 'Pagamentos', value: pagamentos },
      { name: 'Benefícios', value: beneficios },
      { name: 'Descontos', value: descontos },
    ]);
  };

  const handleSave = () => {
    const novaEntrada = { ...formData, id: editingId || Date.now() };

    if (editingId) {
      setFolha((prev) => prev.map((item) => (item.id === editingId ? novaEntrada : item)));
      setSnackbarMessage('Registro atualizado com sucesso!');
    } else {
      setFolha((prev) => [...prev, novaEntrada]);
      setSnackbarMessage('Registro adicionado com sucesso!');
    }

    setModalOpen(false);
    setSnackbarOpen(true);
    setEditingId(null);
    resetForm();
    atualizarResumo();
  };

  const handleEdit = (registro) => {
    setFormData(registro);
    setEditingId(registro.id);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setFolha((prev) => prev.filter((item) => item.id !== id));
    setSnackbarMessage('Registro removido com sucesso!');
    setSnackbarOpen(true);
    atualizarResumo();
  };

  const resetForm = () => {
    setFormData({
      nome: '',
      cargo: '',
      departamento: '',
      salario: '',
      beneficios: '',
      descontos: '',
      dataPagamento: null,
    });
  };

  useEffect(() => {
    atualizarResumo();
  }, [folha]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Resumo Financeiro */}
      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Pagamentos</Typography>
            <Typography variant="h4" color="green">
              R$ {resumo.pagamentos.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Benefícios</Typography>
            <Typography variant="h4" color="blue">
              R$ {resumo.beneficios.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Descontos</Typography>
            <Typography variant="h4" color="red">
              R$ {resumo.descontos.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Custo Total</Typography>
            <Typography variant="h4" color="purple">
              R$ {resumo.custoTotal.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Gráfico de Barras */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Pagamentos por Departamento
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={graficoBarras}>
            <XAxis dataKey="name" />
            <YAxis />
            <RechartTooltip />
            <Bar dataKey="pagamento" fill="#4caf50" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      {/* Gráfico de Pizza */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Distribuição de Custos
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={graficoPizza}
              dataKey="value"
              nameKey="name"
              outerRadius={120}
              fill="#8884d8"
              label={(entry) => `${entry.name}: ${entry.value.toFixed(2)}`}
            >
              {graficoPizza.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index % 2 === 0 ? '#4caf50' : '#f44336'}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Paper>

      {/* Botão Adicionar */}
      <Box sx={{ marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Novo Registro
        </Button>
      </Box>

      {/* Tabela */}
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography variant="h6">Detalhamento da Folha de Pagamento</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Cargo</TableCell>
              <TableCell>Departamento</TableCell>
              <TableCell>Salário</TableCell>
              <TableCell>Benefícios</TableCell>
              <TableCell>Descontos</TableCell>
              <TableCell>Data de Pagamento</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {folha.map((registro) => (
              <TableRow key={registro.id}>
                <TableCell>{registro.nome}</TableCell>
                <TableCell>{registro.cargo}</TableCell>
                <TableCell>{registro.departamento}</TableCell>
                <TableCell>R$ {parseFloat(registro.salario).toFixed(2)}</TableCell>
                <TableCell>R$ {parseFloat(registro.beneficios).toFixed(2)}</TableCell>
                <TableCell>R$ {parseFloat(registro.descontos).toFixed(2)}</TableCell>
                <TableCell>{registro.dataPagamento?.toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(registro)} startIcon={<EditIcon />}>
                    Editar
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(registro.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflow: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" mb={2}>
            {editingId ? 'Editar Registro' : 'Novo Registro'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cargo"
                name="cargo"
                value={formData.cargo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Departamento"
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Salário"
                name="salario"
                type="number"
                value={formData.salario}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Benefícios"
                name="beneficios"
                type="number"
                value={formData.beneficios}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descontos"
                name="descontos"
                type="number"
                value={formData.descontos}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data de Pagamento"
                  value={formData.dataPagamento}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
