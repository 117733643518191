import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Modal,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Avatar,
  Card,
  CardContent,
} from '@mui/material';
import {
  AccountBalance as AccountBalanceIcon,
  AttachMoney as AttachMoneyIcon,
  TrendingUp as TrendingUpIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { BarChart } from '@mui/x-charts';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';


const cardData = [
  { icon: <AccountBalanceIcon />, title: 'Total Geral', value: 'R$ 221.447,76', color: '#4caf50' },
  { icon: <AttachMoneyIcon />, title: 'Total Vencido', value: 'R$ 32.892,22', color: '#f44336' },
  { icon: <TrendingUpIcon />, title: 'Total a Vencer', value: 'R$ 188.555,54', color: '#ffeb3b' },
];

const initialData = [
  { id: 1, codigo: '003404', nome: 'Cliente A', vencimento: '2025-01-10', valor: 123555.54, saldo: 123555.54 },
  { id: 2, codigo: '003021', nome: 'Cliente B', vencimento: '2025-02-15', valor: 65000, saldo: 65000 },
];

export default function ContasReceber() {
  const [data, setData] = useState(initialData);
  const [modalOpen, setModalOpen] = useState(false);
  const [newData, setNewData] = useState({
    codigo: '',
    nomeRazaoSocial: '',
    apelidoFantasia: '',
    fazendaLocal: '',
    obra: '',
    contaContabil: '',
    centroCusto: '',
    movimento: '',
    emissao: new Date().toISOString().split('T')[0],
    valor: '',
    valorParcelar: '',
    meioPagamento: '',
    vendedor: '',
    observacao: '',
    parcelado: false,
    parcelas: [],
  });

  const [parcelas, setParcelas] = useState([]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    clearForm();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const handleParceladoChange = (e) => {
    const { checked } = e.target;
    setNewData({ ...newData, parcelado: checked });
    if (!checked) setParcelas([]);
  };

  const handleParcelaChange = (index, field, value) => {
    const updatedParcelas = [...parcelas];
    updatedParcelas[index][field] = value;
    setParcelas(updatedParcelas);
  };

  const addParcela = () => {
    setParcelas([
      ...parcelas,
      { numero: parcelas.length + 1, valor: '', vencimento: '', meioPagamento: '' },
    ]);
  };

  const handleSave = () => {
    setData([...data, { id: data.length + 1, ...newData, parcelas }]);
    clearForm();
    handleCloseModal();
  };

  const clearForm = () => {
    setNewData({
      codigo: '',
      nomeRazaoSocial: '',
      apelidoFantasia: '',
      fazendaLocal: '',
      obra: '',
      contaContabil: '',
      centroCusto: '',
      movimento: '',
      emissao: new Date().toISOString().split('T')[0],
      valor: '',
      valorParcelar: '',
      meioPagamento: '',
      vendedor: '',
      observacao: '',
      parcelado: false,
      parcelas: [],
    });
    setParcelas([]);
  };

  return (
    <Container sx={{ paddingTop: 4 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 4 }}>
        Contas a Receber
      </Typography>

      {/* Cards de Resumo */}
      <Grid container spacing={4}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card elevation={3}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  sx={{
                    backgroundColor: card.color,
                    width: 56,
                    height: 56,
                    margin: 'auto',
                    marginBottom: 2,
                  }}
                >
                  {card.icon}
                </Avatar>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {card.title}
                </Typography>
                <Typography variant="h5">{card.value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Gráficos */}
      <Grid container spacing={4} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 4, borderRadius: 8 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Distribuição de Contas
            </Typography>
            <BarChart
              width={400}
              height={300}
              series={[
                { label: 'Total Geral', data: [221447.76], color: '#4caf50' },
                { label: 'Total Vencido', data: [32892.22], color: '#f44336' },
                { label: 'Total a Vencer', data: [188555.54], color: '#ffeb3b' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['Totais'] }]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 4, borderRadius: 8 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Resumo por Cliente
            </Typography>
            <BarChart
              width={400}
              height={300}
              series={[
                { label: 'Cliente A', data: [123555.54], color: '#4caf50' },
                { label: 'Cliente B', data: [65000], color: '#ffeb3b' },
              ]}
              xAxis={[{ scaleType: 'band', data: ['Clientes'] }]}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* Botão para Adicionar Conta */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 4 }}>
      <Button
  variant="contained"
  color="primary"
  startIcon={<AddCircleIcon />}
  onClick={handleOpenModal}
>
  Adicionar Conta
</Button>
      </Box>

      {/* Tabela de Contas */}
      <Table sx={{ marginTop: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.codigo}</TableCell>
              <TableCell>{item.nome}</TableCell>
              <TableCell>{item.vencimento}</TableCell>
              <TableCell>R$ {item.valor.toFixed(2)}</TableCell>
              <TableCell>R$ {item.saldo.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
 {/* Modal */}
 <Modal open={modalOpen} onClose={handleCloseModal}>
 <Box
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '90vh', // Limita a altura máxima
    overflowY: 'auto', // Adiciona barra de rolagem vertical
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 3,
  }}
>

          <Typography variant="h6" gutterBottom>
            Nova Conta a Receber
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Código"
                name="codigo"
                value={newData.codigo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome/Razão Social"
                name="nomeRazaoSocial"
                value={newData.nomeRazaoSocial}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Apelido/Nome Fantasia"
                name="apelidoFantasia"
                value={newData.apelidoFantasia}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Fazenda/Local"
                name="fazendaLocal"
                value={newData.fazendaLocal}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Código e Nome da Obra"
                name="obra"
                value={newData.obra}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Código e Nome da Conta Contábil"
                name="contaContabil"
                value={newData.contaContabil}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Código e Centro de Custo"
                name="centroCusto"
                value={newData.centroCusto}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Movimento"
                name="movimento"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={newData.movimento}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Emissão"
                name="emissao"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={newData.emissao}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Valor"
                name="valor"
                type="number"
                value={newData.valor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Valor a Parcelar Documento"
                name="valorParcelar"
                type="number"
                value={newData.valorParcelar}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newData.parcelado}
                    onChange={handleParceladoChange}
                  />
                }
                label="Parcelado"
              />
            </Grid>
            {newData.parcelado && (
              <>
                <Grid item xs={12}>
                  <Button variant="outlined" onClick={addParcela}>
                    Adicionar Parcela
                  </Button>
                </Grid>
                {parcelas.map((parcela, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={4}>
                      <TextField
                        label={`Parcela ${index + 1} - Valor`}
                        type="number"
                        value={parcela.valor}
                        onChange={(e) =>
                          handleParcelaChange(index, 'valor', e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Vencimento"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={parcela.vencimento}
                        onChange={(e) =>
                          handleParcelaChange(index, 'vencimento', e.target.value)
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel>Meio de Pagamento</InputLabel>
                        <Select
                          value={parcela.meioPagamento}
                          onChange={(e) =>
                            handleParcelaChange(index, 'meioPagamento', e.target.value)
                          }
                        >
                          <MenuItem value="Boleto">Boleto</MenuItem>
                          <MenuItem value="Cartão de Crédito">Cartão de Crédito</MenuItem>
                          <MenuItem value="Transferência">Transferência</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Vendedor"
                name="vendedor"
                value={newData.vendedor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Observação"
                name="observacao"
                multiline
                rows={3}
                value={newData.observacao}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
            <Button variant="outlined" onClick={clearForm} sx={{ marginRight: 2 }}>
              Limpar
            </Button>
            <Button variant="contained" onClick={handleSave} sx={{ marginRight: 2 }}>
              Salvar
            </Button>
            <Button variant="outlined" color="error" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>


    </Container>
  );
}
