import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer } from 'recharts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem } from '@mui/material';


export default function DRE() {
  const [resumo, setResumo] = useState({ receitas: 0, despesas: 0, lucro: 0 });
  const [graficoData, setGraficoData] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Campos do formulário
  const [formData, setFormData] = useState({
    descricao: '',
    tipo: 'receita', // "receita" ou "despesa"
    valor: '',
    data: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (value) => {
    setFormData({ ...formData, data: value });
  };

  const atualizarResumo = () => {
    const receitas = entradas
      .filter((item) => item.tipo === 'receita')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const despesas = entradas
      .filter((item) => item.tipo === 'despesa')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const lucro = receitas - despesas;

    setResumo({ receitas, despesas, lucro });
    setGraficoData([
      { name: 'Receitas', value: receitas, color: '#4caf50' },
      { name: 'Despesas', value: despesas, color: '#f44336' },
      { name: 'Lucro', value: lucro, color: '#2196f3' },
    ]);
  };

  const handleSave = () => {
    const novaEntrada = { ...formData, id: editingId || Date.now() };

    if (editingId) {
      setEntradas((prev) => prev.map((item) => (item.id === editingId ? novaEntrada : item)));
      setSnackbarMessage('Entrada/Despesa atualizada com sucesso!');
    } else {
      setEntradas((prev) => [...prev, novaEntrada]);
      setSnackbarMessage('Entrada/Despesa adicionada com sucesso!');
    }

    setModalOpen(false);
    setSnackbarOpen(true);
    setEditingId(null);
    resetForm();
    atualizarResumo();
  };

  const handleEdit = (entrada) => {
    setFormData(entrada);
    setEditingId(entrada.id);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setEntradas((prev) => prev.filter((item) => item.id !== id));
    setSnackbarMessage('Entrada/Despesa removida com sucesso!');
    setSnackbarOpen(true);
    atualizarResumo();
  };

  const resetForm = () => {
    setFormData({
      descricao: '',
      tipo: 'receita',
      valor: '',
      data: null,
    });
  };

  useEffect(() => {
    atualizarResumo();
  }, [entradas]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Resumo Financeiro */}
      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Receitas</Typography>
            <Typography variant="h4" color="green">
              R$ {resumo.receitas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Despesas</Typography>
            <Typography variant="h4" color="red">
              R$ {resumo.despesas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Lucro</Typography>
            <Typography variant="h4" color="blue">
              R$ {resumo.lucro.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Gráfico de Barras */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Resumo Gráfico
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={graficoData}>
            <XAxis dataKey="name" />
            <YAxis />
            <RechartTooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      {/* Botão Adicionar */}
      <Box sx={{ marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Nova Entrada/Despesa
        </Button>
      </Box>

      {/* Tabela de Detalhamento */}
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography variant="h6">Detalhamento D.R.E</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entradas.map((entrada) => (
              <TableRow key={entrada.id}>
                <TableCell>{entrada.descricao}</TableCell>
                <TableCell>{entrada.tipo === 'receita' ? 'Receita' : 'Despesa'}</TableCell>
                <TableCell>R$ {parseFloat(entrada.valor).toFixed(2)}</TableCell>
                <TableCell>{entrada.data?.toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(entrada)} startIcon={<EditIcon />}>
                    Editar
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(entrada.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflow: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" mb={2}>
            {editingId ? 'Editar Entrada/Despesa' : 'Nova Entrada/Despesa'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="descricao"
                value={formData.descricao}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Tipo"
                name="tipo"
                value={formData.tipo}
                onChange={handleChange}
              >
                <MenuItem value="receita">Receita</MenuItem>
                <MenuItem value="despesa">Despesa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Valor"
                name="valor"
                type="number"
                value={formData.valor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data"
                  value={formData.data}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
