import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Breadcrumbs,
  Link,
  Avatar,
  Card,
  CardContent,
  Toolbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';

export default function FluxoDeCaixa() {
  const navigate = useNavigate();
  const [transacoes, setTransacoes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [data, setData] = useState(null);
  const [tipo, setTipo] = useState('');
  const [categoria, setCategoria] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [editingId, setEditingId] = useState(null);

  const API_BASE_URL = 'https://athixdev.xyz/aabb';

  const fetchTransacoes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/fluxo_de_caixa.php`);
      setTransacoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar transações:', error);
    }
  };

  const fetchCategorias = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categorias_transacoes.php`);
      setCategorias(response.data);
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
    }
  };

  const handleSave = async () => {
    const payload = {
      data: data ? data.toISOString().split('T')[0] : null,
      tipo,
      categoria_id: categoria?.id || null,
      descricao,
      valor: parseFloat(valor.replace(/[^0-9.-]+/g, '')) || 0,
    };

    try {
      if (editingId) {
        await axios.put(`${API_BASE_URL}/fluxo_de_caixa.php`, { ...payload, id: editingId });
        setSnackbarMessage('Transação atualizada com sucesso!');
      } else {
        await axios.post(`${API_BASE_URL}/fluxo_de_caixa.php`, payload);
        setSnackbarMessage('Transação adicionada com sucesso!');
      }
      setSnackbarOpen(true);
      setModalOpen(false);
      fetchTransacoes();
      resetForm();
    } catch (error) {
      console.error('Erro ao salvar transação:', error);
      setSnackbarMessage('Erro ao salvar transação.');
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (transacao) => {
    setEditingId(transacao.id);
    setData(transacao.data ? new Date(transacao.data) : null);
    setTipo(transacao.tipo);
    setCategoria(categorias.find((c) => c.nome === transacao.categoria_nome) || null);
    setDescricao(transacao.descricao);
    setValor(transacao.valor);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/fluxo_de_caixa.php`, { data: { id } });
      setSnackbarMessage('Transação removida com sucesso!');
      setSnackbarOpen(true);
      fetchTransacoes();
    } catch (error) {
      console.error('Erro ao excluir transação:', error);
    }
  };

  const resetForm = () => {
    setEditingId(null);
    setData(null);
    setTipo('');
    setCategoria(null);
    setDescricao('');
    setValor('');
  };

  useEffect(() => {
    fetchTransacoes();
    fetchCategorias();
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      {/* Breadcrumb */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 3 }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate(-1)}
          style={{ cursor: 'pointer' }}
        >
          Módulo Financeiro
        </Link>
        <Typography color="text.primary">Fluxo de Caixa</Typography>
      </Breadcrumbs>

      {/* Cards de Resumo */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Avatar sx={{ backgroundColor: '#4caf50', width: 56, height: 56, margin: 'auto' }}>
                R$
              </Avatar>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Total Receitas
              </Typography>
              <Typography variant="h5">R$ 123,456.00</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardContent sx={{ textAlign: 'center' }}>
              <Avatar sx={{ backgroundColor: '#f44336', width: 56, height: 56, margin: 'auto' }}>
                R$
              </Avatar>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Total Despesas
              </Typography>
              <Typography variant="h5">R$ 45,678.00</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Botão para adicionar nova transação */}
      <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Nova Transação
        </Button>
      </Box>

      {/* Tabela de transações */}
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Toolbar>
          <Typography variant="h6">Histórico de Fluxo de Caixa</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transacoes.map((transacao) => (
              <TableRow key={transacao.id}>
                <TableCell>{transacao.data}</TableCell>
                <TableCell>{transacao.tipo}</TableCell>
                <TableCell>{transacao.categoria_nome}</TableCell>
                <TableCell>{transacao.descricao}</TableCell>
                <TableCell>R$ {parseFloat(transacao.valor).toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(transacao)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(transacao.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflowY: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" gutterBottom>
            {editingId ? 'Editar Transação' : 'Nova Transação'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data"
                  value={data}
                  onChange={(newValue) => setData(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              >
                <MenuItem value="Receita">Receita</MenuItem>
                <MenuItem value="Despesa">Despesa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={categorias}
                getOptionLabel={(option) => option.nome || ''}
                value={categoria}
                onChange={(event, newValue) => setCategoria(newValue)}
                renderInput={(params) => <TextField {...params} label="Categoria" fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Valor"
                fullWidth
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
