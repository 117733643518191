import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  MenuItem,
  Stack,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Gerencial() {
  const [resumo, setResumo] = useState({ receitas: 0, despesas: 0, lucro: 0, saldo: 0 });
  const [graficoBarras, setGraficoBarras] = useState([]);
  const [graficoPizza, setGraficoPizza] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Campos do formulário
  const [formData, setFormData] = useState({
    descricao: '',
    tipo: 'receita',
    categoria: '',
    valor: '',
    data: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (value) => {
    setFormData({ ...formData, data: value });
  };

  const atualizarResumo = () => {
    const receitas = entradas
      .filter((item) => item.tipo === 'receita')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const despesas = entradas
      .filter((item) => item.tipo === 'despesa')
      .reduce((acc, item) => acc + parseFloat(item.valor), 0);

    const saldo = receitas - despesas;

    const lucro = receitas - despesas > 0 ? receitas - despesas : 0;

    setResumo({ receitas, despesas, saldo, lucro });

    // Dados do gráfico de barras
    const receitasPorCategoria = entradas
      .filter((item) => item.tipo === 'receita')
      .reduce((acc, item) => {
        acc[item.categoria] = (acc[item.categoria] || 0) + parseFloat(item.valor);
        return acc;
      }, {});

    const despesasPorCategoria = entradas
      .filter((item) => item.tipo === 'despesa')
      .reduce((acc, item) => {
        acc[item.categoria] = (acc[item.categoria] || 0) + parseFloat(item.valor);
        return acc;
      }, {});

    setGraficoBarras(
      Object.keys({ ...receitasPorCategoria, ...despesasPorCategoria }).map((categoria) => ({
        name: categoria,
        receita: receitasPorCategoria[categoria] || 0,
        despesa: despesasPorCategoria[categoria] || 0,
      }))
    );

    // Dados do gráfico de pizza
    setGraficoPizza(
      Object.keys(despesasPorCategoria).map((categoria) => ({
        name: categoria,
        value: despesasPorCategoria[categoria],
      }))
    );
  };

  const handleSave = () => {
    const novaEntrada = { ...formData, id: editingId || Date.now() };

    if (editingId) {
      setEntradas((prev) => prev.map((item) => (item.id === editingId ? novaEntrada : item)));
      setSnackbarMessage('Item atualizado com sucesso!');
    } else {
      setEntradas((prev) => [...prev, novaEntrada]);
      setSnackbarMessage('Item adicionado com sucesso!');
    }

    setModalOpen(false);
    setSnackbarOpen(true);
    setEditingId(null);
    resetForm();
    atualizarResumo();
  };

  const handleEdit = (entrada) => {
    setFormData(entrada);
    setEditingId(entrada.id);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    setEntradas((prev) => prev.filter((item) => item.id !== id));
    setSnackbarMessage('Item removido com sucesso!');
    setSnackbarOpen(true);
    atualizarResumo();
  };

  const resetForm = () => {
    setFormData({
      descricao: '',
      tipo: 'receita',
      categoria: '',
      valor: '',
      data: null,
    });
  };

  useEffect(() => {
    atualizarResumo();
  }, [entradas]);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Resumo Financeiro */}
      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Receitas</Typography>
            <Typography variant="h4" color="green">
              R$ {resumo.receitas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Despesas</Typography>
            <Typography variant="h4" color="red">
              R$ {resumo.despesas.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Saldo</Typography>
            <Typography variant="h4" color="blue">
              R$ {resumo.saldo.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6">Lucro</Typography>
            <Typography variant="h4" color="purple">
              R$ {resumo.lucro.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Gráfico de Barras */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Resumo por Categoria
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={graficoBarras}>
            <XAxis dataKey="name" />
            <YAxis />
            <RechartTooltip />
            <Bar dataKey="receita" fill="#4caf50" />
            <Bar dataKey="despesa" fill="#f44336" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      {/* Gráfico de Pizza */}
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Distribuição de Despesas
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={graficoPizza}
              dataKey="value"
              nameKey="name"
              outerRadius={120}
              fill="#8884d8"
              label={(entry) => `${entry.name}: ${entry.value.toFixed(2)}`}
            >
              {graficoPizza.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index % 2 === 0 ? '#f44336' : '#ff9800'}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Paper>

      {/* Botão Adicionar */}
      <Box sx={{ marginBottom: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
        >
          Novo Item
        </Button>
      </Box>

      {/* Tabela */}
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography variant="h6">Detalhamento Gerencial</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Categoria</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entradas.map((entrada) => (
              <TableRow key={entrada.id}>
                <TableCell>{entrada.descricao}</TableCell>
                <TableCell>{entrada.tipo === 'receita' ? 'Receita' : 'Despesa'}</TableCell>
                <TableCell>{entrada.categoria}</TableCell>
                <TableCell>R$ {parseFloat(entrada.valor).toFixed(2)}</TableCell>
                <TableCell>{entrada.data?.toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(entrada)} startIcon={<EditIcon />}>
                    Editar
                  </Button>
                  <Button
                    color="error"
                    onClick={() => handleDelete(entrada.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            width: '90%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflow: 'auto',
            padding: 4,
            mx: 'auto',
            my: '5%',
            position: 'relative',
          }}
        >
          <Typography variant="h6" mb={2}>
            {editingId ? 'Editar Item' : 'Novo Item'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="descricao"
                value={formData.descricao}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Tipo"
                name="tipo"
                value={formData.tipo}
                onChange={handleChange}
              >
                <MenuItem value="receita">Receita</MenuItem>
                <MenuItem value="despesa">Despesa</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Categoria"
                name="categoria"
                value={formData.categoria}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Valor"
                name="valor"
                type="number"
                value={formData.valor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data"
                  value={formData.data}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
